<template>
	<div class="register_wrap">
		<div class="content">
			<div class="content_left"></div>
			<div class="content_right">
				<div class="top_txt">注册快洋淘账号</div>
				<div class="line_title" style="margin-top: 28px">手机号</div>

				<a-input-group compact class="phone_line">
					<a-select
						:default-value="codeIndex"
						v-model="codeIndex"
						:dropdownMatchSelectWidth="false"
						@change="changeCode">
						<a-select-option
							style="min-width: 150px"
							v-for="(item, index) in phoneCodeList"
							:key="index"
							:value="index">
							{{ `${item.country}(${item.code})` }}
						</a-select-option>
					</a-select>
					<a-input
						style="width: 469px; height: 50px"
						placeholder="输入手机号"
						v-model="phone" />
				</a-input-group>

				<div class="line_title">验证码</div>

				<div class="middle_password_line">
					<a-input
						placeholder="请输入验证码"
						v-model="messagecode"
						style="width: 356px; height: 50px; line-height: 50px" />
					<a-button
						type="primary"
						:loading="get_messagecode_state"
						@click="get_messagecode"
						style="margin-left: 10px; height: 50px">
						获取验证码
					</a-button>
				</div>

				<div class="line_title">推荐码</div>

				<a-input
					placeholder="推荐码 (选填)"
					class="invite_line"
					v-model="invite_code" />

				<a-checkbox v-model="is_agreen" class="agreen_line">
					我已阅读并同意快洋淘浏览器

					<span @click="yonhu" class="yuhu">《用户协议》</span>和<span
						@click="yinse"
						class="yuhu"
						>《隐私政策》</span
					>
				</a-checkbox>

				<a-button type="primary" class="down_btn" @click="go_register">
					确定
				</a-button>

				<div class="down_line" @click="go_login">
					我有账号，直接登录 》
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { user_send_sms, user_register } from "@/api/login";
import { phoneCodeList } from "@/utils/phoneCode.js";
export default {
	data() {
		return {
			invite_code: "",
			codeIndex: 0,
			code: "86",
			phoneCodeList: phoneCodeList,
			phone: "",
			messagecode: null,
			get_messagecode_state: false,

			is_agreen: false,
		};
	},
	mounted() {
		this.invite_code = this.$route.query.invite;

		this.codeIndex = this.phoneCodeList.findIndex(
			item => item.code === "+86"
		);
	},
	methods: {
		changeCode(value) {
			this.code = this.phoneCodeList[value].code.slice(1);
		},
		async get_messagecode() {
			if (this.get_messagecode_state) {
				return;
			}

			let appid = "2006686160";

			let captcha1 = new TencentCaptcha(appid, res => {
				if (res.ret === 0) {
					this.get_messagecode_state = true;
					user_send_sms({
						type: 1,
						phone: this.phone,
						ty_ticket: res.ticket,
						ty_rand_str: res.randstr,
						code: this.code,
					}).then(res => {
						if (res.data.code == 200) {
							this.$message.success(
								"验证码已发出,60秒之后可再次点击获取"
							);

							setTimeout(() => {
								this.get_messagecode_state = false;
							}, 60000);
						} else {
							setTimeout(() => {
								this.get_messagecode_state = false;
							}, 3000);
						}
					});

					// const param = {
					//   data: {
					//     phone: this.phoneNum,
					//     ty_ticket: res.ticket,
					//     ty_rand_str: res.randstr,
					//   },
					// };
					// API.sendCode(param)
					//   .then((response) => {
					//     if (response.data.code === 200) {
					//       // 发送成功
					//       this.startCountDown();
					//     } else {
					//       this.toastInfo = { text: response.msg };
					//     }
					//   })
					//   .catch((err) => {
					//     this.toastInfo = { text: "网络异常，请重试" };
					//   });
				}
			});
			captcha1.show(); // 显示验证码
		},
		yonhu() {
			this.$router.push({ name: "login_agre" });
		},
		yinse() {
			this.$router.push({ name: "login_ckop" });
		},
		go_login() {
			this.$router.push({ name: "login_login" });
		},
		go_out(link) {
			window.open(link);
		},
		async go_register() {
			// if (!~navigator.userAgent.indexOf("YangTao")) {
			//   alert("注册参数错误~");
			//   return false;
			// }

			if (!this.is_agreen) {
				this.$message.error("用户协议没有勾选!");
				return;
			}

			let { data } = await user_register({
				phone: this.phone,
				verify_code: this.messagecode,
				invited_code: this.invite_code,
				code: this.code,
			});
			if (data.code == 200) {
				this.$message.success("注册成功");
				localStorage.member = JSON.stringify(data.data.member);
				localStorage.user = JSON.stringify(data.data.user);
				localStorage.token = data.data.token;
				this.$router.push({ name: "manage_home" });
			} else {
				setTimeout(() => {
					this.get_messagecode_state = false;
				}, 3000);
			}
		},
	},
};
</script>

<style scoped lang="less">
.yuhu {
	color: #4c84ff;
}
.register_wrap {
	// background: #919191;
	background: url(../../assets/img/backnew.png) no-repeat center center;
	background-size: 100% 100%;
	margin-left: 40px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content {
		width: 920px;
		height: 540px;
		display: flex;

		.content_left {
			width: 320px;
			height: 540px;
			background-image: url("../../assets/img/login/left.png");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.content_right {
			background: #fff;
			flex: 1;

			.top_txt {
				margin-left: 43px;
				margin-top: 44px;
				height: 24px;
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #374567;
				line-height: 24px;
			}

			.line_title {
				width: 42px;
				margin-left: 45px;
				margin-top: 14px;
				height: 14px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 14px;
			}

			.phone_line {
				margin-left: 45px;
				margin-top: 12px;
				height: 50px;
				width: 469px;
				display: flex;
				/deep/ .ant-select-selection {
					height: 50px;
				}

				/deep/ .ant-select-selection__rendered {
					line-height: 50px;
				}
			}

			.middle_password_line {
				display: flex;
				height: 50px;
				margin-top: 12px;
				margin-left: 45px;
			}

			.invite_line {
				margin-top: 12px;
				margin-left: 45px;
				height: 50px;
				width: 469px;
			}

			.agreen_line {
				margin-top: 12px;
				margin-left: 45px;

				.content_link {
					cursor: pointer;
					color: #3860f4;
				}
			}

			.down_btn {
				margin-top: 29px;
				margin-left: 45px;
				width: 496px;
				height: 50px;
				border-radius: 2px;
				font-size: 16px;
			}

			.down_line {
				text-align: center;
				margin-top: 35px;
				cursor: pointer;
				height: 14px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #3860f4;
			}
		}
	}
}
</style>
